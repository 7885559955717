
const countDiv = function(args, value, showAnswer) {
  const newNode = document.createElement("span");
  newNode.className += "row";
  // if (showAnswer) {
  newNode.innerHTML = `
    (<span style="color: green;font-size: 14px;cursor: pointer" id="${
      args.tagId
    }-${args.wrongCount}r">正确人数:${args.rightCount}</span>，
    <span style="color: red;font-size: 14px;cursor: pointer" id="${
      args.tagId
    }-${args.wrongCount}w">错误人数:${args.wrongCount}</span>)`;
  // } else {
  //   newNode.innerHTML = ''
  // }

  // if(args.answerRateList && args.answerRateList.length>0){
  //   newNode.innerHTML +=`<p><span style="font-size: 14px;cursor: pointer">[选项统计 </span>`;
  //   args.answerRateList.forEach(a=>{
  //     let label = `${a.answer}:${(a.rate*100).toFixed(0)}%(${a.scount}人) `
  //     if(a.answer === args.answer){
  //       newNode.innerHTML += `<span style="color: green;font-size: 14px;cursor: pointer" id="${args.tagId}-${args.wrongCount}">${label}</span>`
  //     }else{
  //       newNode.innerHTML += `<span style="color: red;font-size: 14px;cursor: pointer" id="${args.tagId}-${args.wrongCount}">${label}</span>`
  //     }
  //   })
  //   newNode.innerHTML +=`]</p>`
  // }
  return newNode;
};

const countStatistics = function(args, domList, size = 14, showAnswer) {
  // console.log(args);
  let mapDom = {};
  for (let j = 0; j < domList.length; j++) {
    const tag = domList[j];
    mapDom[tag.value] = {
      status: false,
      answer: tag.value,
      rate: 0,
      scount: 0
    };
  }
  const newNode = document.createElement("p");
  newNode.className += "row";
  if (showAnswer) {
    let rightCode = null;

    // if (args.rightCount == 0) {
    //   rightCode = '0.00%'
    // } else if (args.wrongCount == 0) {
    //   rightCode = '100.00%'
    // } else if (args.rightCount != 0 && args.wrongCount !== 0) {
    //   rightCode = (args.rightCount / (args.rightCount + args.wrongCount)).toFixed(2) * 100 + '%'
    // }
    rightCode = (args.scoreRate * 100).toFixed(2) + "%";
    newNode.innerHTML = `
    [<span style="color: green;font-size: ${size}px;cursor: pointer" id="${
      args.tagId
    }-${args.wrongCount}r">正确率:${rightCode}</span>,
    <span style="color: red;cursor: pointer;font-size: ${size}px" id="${
      args.tagId
    }-${args.wrongCount}w">错误人数:${args.wrongCount}</span>]`;
  } else {
    newNode.innerHTML = "";
  }

  if (args.answerRateList && args.answerRateList.length > 0) {
    if (showAnswer) {
      newNode.innerHTML += `<span style="font-size: ${size}px;cursor: pointer;color: red"> 选项统计：</span>`;
    } else {
      newNode.innerHTML += "";
    }

    args.answerRateList.forEach(a => {
      mapDom[a.answer].status = true;
      mapDom[a.answer].rate = (a.rate * 100).toFixed(0);
      mapDom[a.answer].scount = a.scount;
      // let label = `${a.answer}:${(a.rate*100).toFixed(0)}%(${a.scount}人) `
      // if(a.answer === args.answer){
      //   newNode.innerHTML += `<span style="color: green;font-size: 14px;cursor: pointer" id="${args.tagId}-${args.wrongCount}${args.answer}">${label}</span>`
      // }else{
      //   newNode.innerHTML += `<span style="color: red;font-size: 14px;cursor: pointer" id="${args.tagId}-${args.wrongCount}${args.answer}">${label}</span>`
      // }
    });
    Object.keys(mapDom).map(key => {
      const a = mapDom[key];
      let label = `${a.answer}:${a.rate}%(${a.scount}人) `;
      if (mapDom[key].answer === args.answer) {
        if (showAnswer) {
          newNode.innerHTML += `<span style="color: green;cursor: pointer;font-size: ${size}px;" id="${
            args.tagId
          }-${args.wrongCount}${args.answer}">${label}</span>`;
        } else {
          newNode.innerHTML += "";
        }
      } else {
        if (showAnswer) {
          newNode.innerHTML += `<span style="color: red;cursor: pointer;font-size: ${size}px" id="${
            args.tagId
          }-${args.wrongCount}${args.answer}">${label}</span>`;
        } else {
          newNode.innerHTML += "";
        }
      }
      // if(!mapDom[key]){
      // let label = `${key}:0%(0人) `
      // newNode.innerHTML += `<span style="color: red;font-size: 14px;cursor: pointer" id="${args.tagId}-${args.wrongCount}${args.answer}">${label}</span>`
      // }
    });
  }
  return newNode;
};

const addAnswer = function(
  args,
  showAnswer = true,
  showListen = true,
  size = 14
) {
  const newNode = document.createElement("span");
  newNode.className += "row";
  if (args.ttsText && showListen) {
    newNode.innerHTML += `
      <p id="${
        args.tagId
      }p" style="color: #409EFF;font-size:${size}px;cursor: pointer">听力原文：<span>${
      args.ttsText
    }</span></p>`;
  }
  if (showAnswer) {
    newNode.innerHTML += `
    <span tabindex="-1" name="${
      args.tagId
    }" style="color: green;font-size: ${size}px;cursor: pointer">正确答案：${
      args.answer
    }</span>`;
  } else {
    newNode.innerHTML += "";
  }
  return newNode;
};

const addAudio = function(args, showAnswer) {
  const newNode = document.createElement("div");
  if (args.speakingAttachmentUrl && args.speakingAttachmentUrl !== "") {
    if (args.speakingAttachmentUrl.indexOf("|") > 0) {
      let list = args.speakingAttachmentUrl.split("|");
      list.map(a => {
        if (a.startsWith("https")) {
          let audio = `<audio src="${a}" controls="controls" preload="none"></audio>`;
          newNode.innerHTML += audio;
        }
      });
    } else {
      newNode.innerHTML = `<audio src="${
        args.speakingAttachmentUrl
      }" controls="controls" preload="none"></audio>`;
    }
  }
  return newNode;
};

export default {
  countDiv,
  countStatistics,
  addAnswer,
  addAudio
};
