/***暂停视频和音频播放**/
export function handlePauseUtil(tag) {
    let content = document.getElementById(tag);
    if(content){
      const audioList = content.getElementsByTagName('audio');
      const videoList = content.getElementsByTagName('video');
      for(let i=0;i<audioList.length;i++) {
        audioList[i].pause();
      }
      for(let i=0;i<videoList.length;i++) {
        videoList[i].pause();
      }
    }
  }
  export function getInputSortUtil(flows) {
    if (flows && flows.length > 0) {
      flows.forEach(item => {
        const domList = document.querySelectorAll(`[name="${item.tagId}"]`);
  
        if (item.tagId.includes("text")) {
          const tag = domList[0];
          const parent = tag.parentNode;
          const n = document.createElement("span");
          if (tag) {
            n.style.borderBottom = `1px solid blue`;
            n.innerHTML = `${item.questionNumber}(${item.answer})`;
            // tag.setAttribute("placeholder",`${item.sort}(${item.answer})`);
            parent.replaceChild(n, tag);
          }
        }
        if (domList && domList.length > 0) {
          //console.log(domList, 'domList====')
          if (domList.length > 1) {
            for (let j = 0; j < domList.length; j++) {
              const tag = domList[j];
              if (item.answer.toUpperCase().includes(tag.value)) {
                tag.checked = true;
              }
            }
          }
        }
      });
    }
}
export function getAudioUtil(paper, fontSize = 14) {
    const domList = document.querySelectorAll(`audio`);
  
    if (domList && domList.length > 0) {
      if (paper.listeningText !== null && paper.listeningText !== "") {
        let regex = new RegExp(/<p.*?(?:>|\/>)/gi); // 匹配所有行元素
        let arrsP = paper.listeningText.match(regex); // obj.info 后台返回的富文本数据
        if (arrsP === null) {
          const lines =
            paper.listeningText && paper.listeningText !== ""
              ? paper.listeningText.split("#")
              : [];
          for (let i = 0; i < domList.length; i++) {
            const tag = domList[i];
            const parent = tag.parentNode;
            const newNode = document.createElement("div");
            newNode.innerHTML = lines[i]
              ? `<span style="color: #409EFF;font-size: ${fontSize}px">听力原文：${
                  lines[i]
                }</span>`
              : "";
            parent.after(newNode);
          }
        } else {
          let mapTag = getMapTag(paper.listeningText);
          for (let i = 0; i < domList.length; i++) {
            const tag = domList[i];
            const parent = tag.parentNode;
            if (Object.keys(mapTag).length > 0 && paper.showListening === 1) {
              const newNode = document.createElement("div");
              newNode.style.color = "#409EFF";
              newNode.innerHTML = mapTag[i]
                ? `<span style="color: #409EFF;font-size: ${fontSize}px">听力原文：</span>${
                    mapTag[i].text
                  }`
                : "";
              parent.after(newNode);
            }
          }
        }
      }
    }
}
  /**排序**/
export function arraySort(property) {
    return function(a, b) {
      return a[property] - b[property];
    };
  }