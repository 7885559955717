<template>
  <div class="paper-content-pre">
    <van-loading class="loading-pre" v-if="loading" color="#1989fa" size="24px"
      >loading...</van-loading
    >
    <div
      v-if="speakShow && task.show"
      class="img-content"
      :style="{ height: `${fHeight}px` }"
    >
      <img v-if="task.url !== ''" :src="task.url" alt="" style="width: 100%" />
      <p v-else>未能发现对应的资源！！！</p>
    </div>
    <div class="statistics" id="statistics" ref="paper" v-else>
      <div class="header">
        <div style="text-align: center;">
          <i class="el-icon-collection left-icon"></i>
          <span style="margin-left: 10px">{{ exam.title }}</span>
        </div>
        <div style="text-align: center;">
          （分值：{{ exam.totalScore }}分） 试题数：{{ total }}
        </div>
      </div>

      <div
        id="content1"
        v-if="!speakShow"
        v-html="exam.content"
        class="exam-content"
      ></div>

      <div class="card" v-if="exam.type === '5'">
        <div class="hard">
          <span>答题卡</span>
        </div>
        <div
          id="answerSheet"
          style="margin: 10px 20px;word-wrap:break-word;"
          v-html="exam.answerSheet"
        ></div>
      </div>

      <div v-if="speakShow && !task.show" class="speaking">
        <div id="speaking" class="speaking-content" v-html="exam.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Loading } from "vant";
import { mapQuery } from "../util/URLUtils";
import req from "../util/req";
import {
  handlePauseUtil,
  getInputSortUtil,
  getAudioUtil,
  arraySort,
} from "@/util/preTask";
import insert from "@/util/insertDomPc";
export default {
  name: "preview",
  data() {
    return {
      loading: false,
      query: mapQuery(),
      firstData: {},
      open: true,
      exam: {},
      total: 0,
      // content:'',
      answerSheet: "",
      speakShow: false,
      flow: {},
      imgUrl: "",
      task: {
        show: false,
      },
      full: true,
      fHeight: document.documentElement.clientHeight - 100,
    };
  },
  components: {
    [Loading.name]: Loading,
  },
  mounted() {
    this.getExam();
  },
  methods: {
    getExam() {
      let param = { id: this.query.id };
      this.loading = true;
      req.post("/api/exam/taskPaper", param).then((res) => {
        this.loading = false;
        const { data } = res.data;
        console.log(this.data);
        this.init(data);
      });
    },
    downloadWord() {
      let html = this.$refs["paper"].outerHTML;
      // const docx = htmlDocx.asBlob(html); // 将HTML内容转换为Word文档的Blob对象
      const docx = new Blob(
        [
          `<!DOCTYPE html><head><meta charset="utf-8"><meta name="viewport" content="width=device-width,initial-scale=1.0"><style  type="text/css"></style ></head > <body>${html}</body></html > `,
        ],
        { type: "application / msword" }
      );
      const url = window.URL.createObjectURL(docx); // 创建一个临时的URL对象
      // 创建一个a标签并设置href属性为生成的URL，以及下载属性为文件名
      const link = document.createElement("a");
      link.href = url;
      link.download = `${this.exam.title}.docx`;
      // 触发点击事件来下载文件
      document.body.appendChild(link);
      link.click();
      // 下载完成后移除临时URL对象和a标签
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
    removeAllWidth(html) {
      return html.replace(/<td\b([^>]*)>/gi, (match, attrs) => {
        // 1. 移除 width 属性
        let cleaned = attrs.replace(
          /\s+width\s*=\s*(?:"[^"]*"|'[^']*'|\S+)/gi,
          ""
        );

        // 2. 移除 style 中的 width 样式
        cleaned = cleaned.replace(
          /\s+style\s*=\s*(["'])(.*?)\1/gi,
          (_, quote, style) => {
            const newStyle = style
              .replace(/\s*width\s*:\s*[^;]+;?/gi, "") // 删除 width 样式
              .replace(/;+/g, ";") // 合并多余分号
              .replace(/^;|;$/g, "") // 去除首尾分号
              .trim();
            return newStyle ? ` style=${quote}${newStyle}${quote}` : "";
          }
        );

        // 3. 清理残留的空 style 属性
        cleaned = cleaned
          .replace(/\s*style\s*=\s*["']\s*["']/gi, "") // 移除 style=""
          .replace(/\s{2,}/g, " ") // 合并多余空格
          .trim();

        return cleaned ? `<td ${cleaned}>` : "<td>";
      });
    },
    removeAllColWidth(html) {
      return html.replace(/<col\b([^>]*)>/gi, (match, attrs) => {
        // 1. 移除 width 属性
        let cleaned = attrs.replace(
          /\s+width\s*=\s*(?:"[^"]*"|'[^']*'|\S+)/gi,
          ""
        );

        // 2. 移除 style 中的 width 样式
        cleaned = cleaned.replace(
          /\s+style\s*=\s*(["'])(.*?)\1/gi,
          (_, quote, style) => {
            const newStyle = style
              .replace(/\s*width\s*:\s*[^;]+;?/gi, "") // 删除 width 样式
              .replace(/;+/g, ";") // 合并多余分号
              .replace(/^;|;$/g, "") // 去除首尾分号
              .trim();
            return newStyle ? ` style=${quote}${newStyle}${quote}` : "";
          }
        );

        // 3. 清理残留的空 style 属性
        cleaned = cleaned
          .replace(/\s*style\s*=\s*["']\s*["']/gi, "") // 移除 style=""
          .replace(/\s{2,}/g, " ") // 合并多余空格
          .trim();

        return cleaned ? `<col ${cleaned}>` : "<col>";
      });
    },
    init(data, tk = null) {
      if (this.equals(this.firstData, data)) {
        return false;
      } else {
        this.firstData = { ...data };
        this.open = true;
        this.exam = data;
        this.exam.content = this.removeAllWidth(this.exam.content); //去掉表格td宽度
        this.exam.content = this.removeAllColWidth(this.exam.content); //去掉表格col宽度
        // this.exam.content = this.exam.content.replace(/<img/g, '<img style="width: 350px"')
        //时间预览去掉小旗子标记
        this.exam.content = this.exam.content.replace(
          /<img[^>]*src="https:\/\/jiongyi-1305135066\.cos\.ap-shanghai\.myqcloud\.com\/20241018105529\.png"[^>]*>/gi,
          ""
        );
        //5个连在一起的图片统一宽度
        this.exam.content = this.exam.content.replace(
          /(<img[^>]*>){5}/g,
          function(match) {
            return match.replace(/<img/g, '<img style="width: 350px"');
          }
        );
        if (tk) this.task = tk;
        this.speakShow = false;
        if (
          data.type === "3" ||
          data.type === "6" ||
          data.type === "10" ||
          data.type === "11"
        )
          this.speakShow = true;
        let mapTag = {};
        this.urls = [];
        const self = this;
        this.exam.flows = this.exam.flows.sort(arraySort("sort"));
        // console.log(this.exam.flows);
        if (this.exam.flows && this.exam.flows.length > 0) {
          this.total = this.exam.flows.length;
          this.exam.flows.forEach((f, index) => {
            mapTag[f.tagId] = f;
            f.urls = [];
            if (f.speakingAttachmentUrl && f.speakingAttachmentUrl !== "") {
              if (f.speakingAttachmentUrl.includes("|")) {
                //type:1,播放，2：停顿，3：准备时间，4：录音时长
                let list = f.speakingAttachmentUrl.split("|");
                list.forEach((u) => {
                  let item = { type: 1, value: 0, src: "" };
                  if (u.indexOf("$") > -1) {
                    item.type = 2;
                    item.value = parseInt(u.substr(1, u.length));
                  } else {
                    item.src = u;
                  }
                  f.urls.push(item);
                });
              } else {
                f.urls.push({
                  type: 1,
                  value: 0,
                  src: f.speakingAttachmentUrl,
                });
              }
            }
            if (f.prepareSeconds && f.prepareSeconds > 0) {
              f.urls.push({ type: 3, value: f.prepareSeconds, src: "" });
            }
            if (f.speakingSeconds && f.speakingSeconds > 0) {
              f.urls.push({ type: 4, value: f.speakingSeconds, src: "" });
            }
          });
        }

        if (this.speakShow && !this.task.show) {
          this.$nextTick(() => {
            this.editorSpeakingContent();
          });
          // getInputSortUtilredio(this.exam.flows)
        } else {
          setTimeout(() => {
            this.exam.showListening = 1; //预览时，显示听力原文
            getAudioUtil(this.exam);
            getInputSortUtil(this.exam.flows);
          }, 100);
        }

        this.$nextTick(() => {
          let audioArr = document.querySelectorAll("audio");
          this.makeOnePlay(audioArr);
        });
      }
    },
    // 判断两个对象是否相等
    equals(obj1, obj2) {
      // 首先判断是否是引用类型
      var f1 = obj1 instanceof Object;
      var f2 = obj2 instanceof Object;
      // 如果有一个不是引用数据类型，那就进行直接判断。（内存地址不同）
      if (!f1 || !f2) {
        return obj1 === obj2;
      }
      // 若全是引用类型的，那就先看一下属性值的长度是否相等，若不相等，就直接false啦。
      if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
      }
      /**
       * 若属性值的长度相等，就接着遍历里边的每一个属性，还是先看里边的属性是哪一个类型，
       * 如果全是引用类型，那就接着对里边的属性调用equals递归函数。
       * 如果不全是引用类型，那就比较这两个值是否相等，若不相等就直接false啦。
       */
      for (var p in obj1) {
        var a = obj1[p] instanceof Object;
        var b = obj2[p] instanceof Object;
        if (a && b) {
          this.equals(obj1[p], obj2[p]);
        } else if (obj1[p] != obj2[p]) {
          return false;
        }
      }
      return true;
    },
    // 音频只能播放一个
    makeOnePlay(audioArr) {
      for (let i = 0; i < audioArr.length; i++) {
        let audio = audioArr[i];
        audio.addEventListener("play", function(e) {
          for (let j = 0; j < audioArr.length; j++) {
            if (j !== i) {
              audioArr[j].pause();
            }
          }
        });
      }
    },
    editorSpeakingContent() {
      // this.content = this.exam.content;
      const hrList = document.querySelectorAll("hr");
      let flows = this.exam.flows.sort(arraySort("sort"));
      let content1 = document.getElementById("speaking");
      if (hrList.length === 0) {
        content1.append(insert.addAudio(flows[0], true));
        content1.append(insert.addAnswer(flows[0]));
      } else {
        flows.forEach((item, index) => {
          const hr = hrList[index];
          if (hr !== undefined) {
            hr.before(insert.addAudio(item, true));
            hr.before(insert.addAnswer(item));
          }
          if (index === flows.length - 1 && hr === undefined) {
            content1.append(insert.addAudio(item, true));
            content1.append(insert.addAnswer(item));
          }
        });
      }
    },

    changeQuestionContent(value) {
      this.flow = this.exam.flows[value];
    },

    cancel() {
      handlePauseUtil("content1");
      this.open = false;
      this.firstData = {};
    },

    fullHandle() {
      this.full = !this.full;
      this.fHeight = !this.full
        ? `640`
        : `${document.documentElement.clientHeight - 100}`;
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.page-icon {
  position: absolute;
  right: 60px;
  top: 20px;
  color: #909399;
  cursor: pointer;
}
.paper-content-pre {
  margin: 0 auto;
  position: relative;
  border: 1px solid #cccccc;
  overflow-y: auto;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  .img-content {
    p {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      letter-spacing: 2px;
      margin-top: 80px;
    }
  }
  .statistics {
    overflow: auto;
    position: relative;
    overflow-x: hidden;
    // height: calc(100vh - 110px);
    .header {
      //   height: 40px;
      padding: 5px 10px;
      color: white;
      font-size: 16px;
      //   display: flex;
      //   align-items: center;
      //   justify-content: space-between;
      background-color: rgba(0, 160, 233, 0.6);
      .left-icon {
        line-height: 40px;
        width: 40px;
        text-align: center;
        background-color: #0a6cd6;
      }
    }
    ::v-deep .exam-content {
      white-space: normal;
      word-break: keep-all;
      overflow-y: auto;
      overflow-x: auto;
      padding: 10px 10px 30px 10px !important;
      // box-sizing: border-box;
      img {
        max-width: calc(100% - 20px) !important;
      }
      img,
      div,
      p,
      span {
        word-wrap: break-word !important;
        width: calc(100% - 20px) !important;
      }
    }
    .exam-content >>> p {
      white-space: normal;
      word-break: keep-all;
    }
    .exam-content >>> span {
      white-space: normal;
      word-break: keep-all;
    }

    .content {
      width: 100%;
      padding: 10px;
    }
    .card {
      margin: 10px;
      border: 1px solid #f3f3f3;
      .hard {
        height: 40px;
        background-color: #f3f3f3;
        span {
          line-height: 40px;
          margin-left: 20px;
        }
      }
    }
    ::v-deep .speaking {
      position: relative;
      .speaking-content {
        white-space: normal;
        word-break: keep-all;
        overflow-y: auto;
        overflow-x: auto;
        width: 100vw;
        padding: 10px 10px 30px 10px !important;
        box-sizing: border-box;
        // padding-left: 10px !important;
        img {
          max-width: calc(100% - 20px) !important;
        }
        div,
        p,
        span {
          word-wrap: break-word !important;
          width: 100% !important;
        }
      }
      .speaking-content >>> p {
        line-height: 32px;
      }
      .speaking-content >>> span {
        line-height: 28px;
      }
    }
  }
  .bottom {
    width: 100%;
    height: 60px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    .label {
      font-size: 22px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
    }
    .two {
      display: flex;
      justify-content: center;
    }
    .three {
      .right-div {
        padding: 1px;
        border-radius: 2px;
        border: 1px dashed #666666;
        .second-div {
          width: 300px;
          margin: 0 2px;
          box-sizing: border-box;
          background-color: white;
        }
      }
    }
  }
}
.export-pdf {
  position: absolute;
  right: 90px;
  top: 16px;
}
.loading-pre {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
::v-deep table {
  width: 100% !important; /* 替代 100vw */
  table-layout: fixed; /* 固定列宽分配 */
  border-collapse: collapse; /* 合并边框 */
  tr {
    width: 100%;
  }
  td {
    // word-break: break-all;
    word-wrap: break-word !important;
    width: auto !important;
    min-width: 100px;
    &:first-of-type {
      width: 100px !important
    }
  }
  audio,img {
    width: 100%;
  }

}
</style>
